import React, { useState } from "react";
import End from "./End";
const Quiz = () => {
  // Beispiel-Fragen
  const questions = [
    {
      question: "Ich möchte nicht, dass wir traurig, kizgin schlafen gehen...",
      options: [
        "Ben sanki cok istiyorum",
        "Yapmasaydin",
        "Konusma benimle",
        "Ich auch nicht 🥺",
      ],
      answer: "Ich auch nicht 🥺",
      message:
        "Bana kizgin olabilirsin daha, ama gel be gülüm gecemizi güzellestirelim 😕",
    },
    {
      question: "Wollen wir mit dem kleinen Quiz anfangen?",
      options: [
        "Ja, ich bin bereit",
        "Nein, ich bin nicht bereit",
        "Vielleicht",
        "Ich bin nicht sicher",
      ],
      answer: "Ja, ich bin bereit",
      message: "Hadi gel baslayalim be gülüm :)",
    },
    {
      question: "Kudret sevdigini umursamayan biri mi?",
      options: ["Evet", "Belki", "Tabikide hayir", "No comment"],
      answer: "Tabikide hayir",
      message:
        "Erkekler mantik ile hareket ettigi icin bazen ister istemez 'umuramiyormus' gibi gelebiliyor cevaplar. Ama gercekten böyle bir durum yok. Söyledigin hersey, yaptigin hersey benim ilgi alanim oldugundan, 'umursamiyorum' gibi düsünme olur mu cennet bahcem benim ❤️",
    },
    {
      question: "Saliha Kudretin neyi oluyor?",
      options: [
        "Sevgilisi",
        "Esi",
        "Arkadasi",
        "Cennet bahcesi, Can kusu, Ömrünün bahari, Melegi, Can parcasi, .... 🥰",
      ],
      answer:
        "Cennet bahcesi, Can kusu, Ömrünün bahari, Melegi, Can parcasi, .... 🥰",
      message:
        "Dini acidan sevgili degilizde ama InshAllah bir gün Esim olursun 😌",
    },
    {
      question: "Kudretin bu dünyada en cok neyi deger veriyor?",
      options: [
        "Rabbine, Dinine, Salihaya",
        "Hicbirseyi",
        "Ise, Okula, Dersine",
        "Oyuna, Futbola, Icardiye",
      ],
      answer: "Rabbine, Dinine, Salihaya",
      message:
        "Rabbim ve Dinim haric bu dünyada en cok deger verdigim tek kisisin güzel insan 🥰",
    },
    {
      question: "Saliha: Cikolatali keksin",
      options: [
        "Kudret: Kalbimi fethettin",
        "Kudret: Bu alemde teksin",
        "Kudret: Tatli bir hevesin",
        "Kudret: ...",
      ],
      answer: "Kudret: Bu alemde teksin",
      message:
        "Cikolatali Kekimsin, bu alemdede benimsin InshAlah can parcam 🥰",
    },
    {
      question: "Konnte ich dir ein lächeln ins Gesicht zaubern?",
      options: ["Vielleicht 🥲", "Auf keinen Fall 🥲", "Ja 🥰", "Sei leise..."],
      answer: "Ja 🥰",
      message: "Ja desene 🥺",
    },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showNextButton, setShowNextButton] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // Zustandsvariable für das Popup
  const [message, setMessage] = useState(""); // Zustandsvariable für die Nachricht im Popup
  const [quizCompleted, setQuizCompleted] = useState(false); // Zustandsvariable für Quiz-Status

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (option === questions[currentQuestion].answer) {
      // Überprüfen, ob die ausgewählte Option die richtige Antwort ist
      setTimeout(() => {
        handleNextQuestion(); // Automatisch zur nächsten Frage übergehen
      }, 1000); // Verzögerung für die visuelle Rückmeldung, hier 1000ms (1 Sekunde)
    } else {
      setMessage(questions[currentQuestion].message); // Setze die Nachricht entsprechend der aktuellen Frage
      setShowPopup(true); // Zeige das Popup an, wenn die Antwort falsch ist
    }
  };

  const handleNextQuestion = () => {
    // Wenn die aktuelle Frage nicht die letzte ist, aktualisiere currentQuestion
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowNextButton(false); // Verstecke den "Weiter"-Button für die nächste Frage
    } else {
      // Wenn alle Fragen beantwortet wurden, markiere das Quiz als abgeschlossen
      setQuizCompleted(true);
    }
  };

  const closePopup = () => {
    setShowPopup(false); // Schließe das Popup
    setMessage(""); // Setze die Nachricht zurück
    setSelectedOption(""); // Setze die ausgewählte Option zurück
  };

  return (
    <div className="flex flex-col items-center justify-center mt-4">
      {quizCompleted ? ( // Wenn das Quiz abgeschlossen ist, render die </End> Komponente
        <div>
          {" "}
          <End />{" "}
        </div>
      ) : (
        <>
          <div className="rounded-full mb-5 w-80 h-80 flex items-center justify-center bg-white">
            <p className="text-center">{questions[currentQuestion].question}</p>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-5">
            {questions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                className={`w-40 h-20 rounded overflow-hidden word-wrap text-xs ${
                  selectedOption === option
                    ? option === questions[currentQuestion].answer
                      ? "bg-green"
                      : "bg-red"
                    : "bg-white"
                }`}
                onClick={() => handleOptionSelect(option)}
                disabled={showNextButton} // Deaktiviere die Optionen, sobald der "Weiter"-Button angezeigt wird
              >
                {option}
              </button>
            ))}
          </div>
          {showNextButton && (
            <button
              className="mt-5 bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleNextQuestion}
            >
              Weiter
            </button>
          )}
          {showPopup && (
            <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-4 rounded shadow">
                <p className="mb-2">{message}</p>
                <button
                  className="bg-pink-200 text-white px-4 py-2 rounded"
                  onClick={closePopup}
                >
                  Anladim 🤗
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Quiz;
