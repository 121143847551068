import React from "react";

const Navbar = () => {
  return (
    <div className="navbar bg-pink-200">
      <div className="flex-1">
        <a className="btn btn-ghost text-xl text-center">S❤️K</a>
      </div>
      <div className="flex-none">
        <div className="dropdown dropdown-end">
          <div tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img alt="Tailwind CSS Navbar component" src="/img/logo.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
