import React, { useState, useRef, useEffect } from "react";

const End = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentMinutes, setCurrentMinutes] = useState(0);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const audioRef = useRef();
  const [showPopup, setShowPopup] = useState(true); // Zustand für das Popup

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    setDuration(audio.duration);
  }, []);

  useEffect(() => {
    const minutes = Math.floor(currentTime / 60);
    const seconds = Math.floor(currentTime % 60);
    setCurrentMinutes(minutes);
    setCurrentSeconds(seconds);
  }, [currentTime]);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (!audio) return;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    if (!audio) return;
    setCurrentTime(audio.currentTime);
  };

  const handleSeek = (time) => {
    const audio = audioRef.current;
    if (!audio) return;
    audio.currentTime = time;
    setCurrentTime(time);
  };

  const handleEndConfirmation = () => {
    setShowPopup(false); // Schließe das Popup, wenn der Benutzer auf den Button klickt
  };

  return (
    <div>
      {showPopup && ( // Zeige das Popup, wenn showPopup true ist
        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-2 rounded shadow">
            <p className="mb-2">
              Ich entschuldige mich für den Vorfall von heute. Ich wollte dich
              eigentlich fragen, ob wir den Trend machen, sobald wir verlobt
              sind. Dass du gefragt hast, hat mich glücklich gemacht. Meine
              Antwort war nicht, dass wir es nicht machen sollten, sondern dass
              es nicht deine erste Reaktion sein wird. Es war nicht meine
              Absicht, deine Freude zu dämpfen. Als du meintest, ich hätte dir
              die Freude genommen, wollte ich nicht weiter darauf eingehen, weil
              ich gemerkt habe, dass es dich verletzt hat. Auch für den
              unangekündigten Anruf abends entschuldige ich mich. Ich dachte, du
              wärst schon zu Hause und wollte nur mit dir reden. Es tut mir
              leid, dass ich dich gestört habe. Es macht mich traurig, wenn du
              verletzt bist. Ich verspreche, in Zukunft reifer zu handeln. Lass
              uns aus unseren Fehlern lernen und den Abend schön beenden. Ich
              liebe dich mein Schatz 🕊️❤️
            </p>
            <button
              className="bg-pink-200 text-white px-4 py-2 rounded"
              onClick={handleEndConfirmation}
            >
              Bende seni seviyorum 🤗
            </button>
          </div>
        </div>
      )}

      {/* Inhalt wird nur gerendert, wenn das Popup geschlossen ist */}
      {!showPopup && (
        <div>
          <div className="x-4 py-2 rounded mb-5 w-80 h-80 flex items-center justify-center">
            <img src="/img/we.jpg" alt="dua" />
          </div>

          <p className="mt-6 text-left font-bold">Can Parcam</p>
          <p className="text-left text-xs">Kudret Ünal</p>

          <div className="mt-4">
            <div className="relative w-full bg-white h-4 rounded-full overflow-hidden">
              <div
                className="absolute top-0 left-0 bg-pink-300 h-full"
                style={{
                  width: duration ? `${(currentTime / duration) * 100}%` : "0%",
                }}
              />
              <input
                type="range"
                min={0}
                max={duration}
                value={currentTime}
                onChange={(e) => handleSeek(parseFloat(e.target.value))}
                className="w-full h-full cursor-pointer opacity-0"
              />
            </div>
            <div className="text-left mt-2 text-xs">
              <p>{`${currentMinutes < 10 ? "0" : ""}${currentMinutes}:${
                currentSeconds < 10 ? "0" : ""
              }${currentSeconds}`}</p>
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <button
              className="rounded-full h-20 w-20 mr-4 text-4xl text-pink-300"
              onClick={() => handleSeek(0)}
            >
              ⏮
            </button>

            <button
              className="rounded-full text-white h-20 w-20 text-4xl bg-pink-300"
              onClick={handlePlayPause}
            >
              {isPlaying ? "⏸" : "⏵"}
            </button>

            <button
              className="rounded-full h-20 w-20 ml-4 text-4xl text-pink-300"
              onClick={() => handleSeek(Math.min(duration, currentTime + 10))}
            >
              ⏭
            </button>
          </div>
          <audio
            ref={audioRef}
            src="/mp3/dua.mp3"
            type="audio/mpeg"
            onTimeUpdate={handleTimeUpdate}
            onEnded={() => setIsPlaying(false)}
          />
        </div>
      )}
    </div>
  );
};

export default End;
